import React, { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

export default function Countdown({
    font, // The font object from Framer
    fontColor,
    resetTime, // Used to specify the daily reset time
    numberFontSize, // Font size for the numbers
    textFontSize, // Font size for the text labels (e.g., Timer, Minutter)
    componentGap, // New property to control the space between components
}) {
    const calculateTargetDate = () => {
        const now = new Date()
        const resetHour = resetTime ? parseInt(resetTime.split(":")[0], 10) : 0
        const resetMinute = resetTime
            ? parseInt(resetTime.split(":")[1], 10)
            : 0
        const resetSecond = resetTime
            ? parseInt(resetTime.split(":")[2], 10)
            : 0

        const todayReset = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            resetHour,
            resetMinute,
            resetSecond
        )

        if (todayReset < now) {
            // If the reset time has already passed today, set it to tomorrow
            todayReset.setDate(todayReset.getDate() + 1)
        }

        return +todayReset
    }

    const calculateTimeLeft = (targetDate) => {
        const difference = targetDate - +new Date()
        const time = {
            h: Math.max(Math.floor((difference / (1000 * 60 * 60)) % 24), 0),
            m: Math.max(Math.floor((difference / (1000 * 60)) % 60), 0),
            s: Math.max(Math.floor((difference / 1000) % 60), 0),
        }

        return {
            hours: time.h,
            minutes: time.m,
            seconds: time.s,
        }
    }

    const [targetDate, setTargetDate] = useState(calculateTargetDate())
    const [timeLeft, setTimeLeft] = useState(
        calculateTimeLeft(calculateTargetDate())
    )
    const [isReady, setIsReady] = useState(false) // To control the initial render state

    useEffect(() => {
        // Update target date and time immediately after component mounts
        const initialTargetDate = calculateTargetDate()
        setTargetDate(initialTargetDate)
        setTimeLeft(calculateTimeLeft(initialTargetDate))
        setIsReady(true) // Now the component is ready to show the correct time

        const updateTimer = () => {
            const updatedTimeLeft = calculateTimeLeft(targetDate)
            if (
                updatedTimeLeft.hours === 0 &&
                updatedTimeLeft.minutes === 0 &&
                updatedTimeLeft.seconds === 0
            ) {
                // When the timer hits 00:00:00, reset the target date and time left
                const newTargetDate = calculateTargetDate()
                setTargetDate(newTargetDate)
                setTimeLeft(calculateTimeLeft(newTargetDate)) // Immediately show 00:00:00 and reset
            } else {
                // Update time left every second
                setTimeLeft(updatedTimeLeft)
            }
        }

        const timer = setInterval(updateTimer, 1000)

        return () => clearInterval(timer)
    }, [targetDate])

    const labels = {
        hours: "TIMER", // Danish for "Hours"
        minutes: "MINUTTER", // Danish for "Minutes"
        seconds: "SEKUNDER", // Danish for "Seconds"
    }

    // Default or placeholder value for the countdown
    const defaultTime = { hours: 0, minutes: 0, seconds: 0 }

    // Set the timer to the default time if isReady is false
    const finalTimeLeft = isReady ? timeLeft : defaultTime

    const timerComponents = []

    // Always display hours, minutes, and seconds, even when they are 0
    Object.keys(finalTimeLeft).forEach((interval) => {
        timerComponents.push(
            <span
                key={interval}
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    opacity: isReady ? 1 : 0, // Ensure visibility after isReady is true
                    transition: "opacity 0.3s ease", // Smooth transition when changing opacity
                }}
            >
                <span
                    style={{
                        fontFamily: font ? font.fontFamily : "Inter", // Apply fontFamily from the font object
                        fontWeight: font ? font.fontWeight : "400", // Apply fontWeight from the font object
                        fontSize: numberFontSize || 30,
                        marginBottom: "0px", // No margin between number and label
                    }}
                >
                    {finalTimeLeft[interval] || 0}{" "}
                    {/* Always show 0 if the time is 0 */}
                </span>
                <span
                    style={{
                        fontFamily: font ? font.fontFamily : "Inter", // Apply fontFamily from the font object
                        fontWeight: font ? font.fontWeight : "400", // Apply fontWeight from the font object
                        fontSize: textFontSize || 12,
                    }}
                >
                    {labels[interval]}
                </span>
            </span>
        )
    })

    return (
        <div
            style={{
                fontFamily: font ? font.fontFamily : "Inter", // Apply fontFamily from the font object
                fontWeight: font ? font.fontWeight : "400", // Apply fontWeight from the font object
                color: fontColor,
                display: "flex",
                gap: `${componentGap}px`, // Apply componentGap as a value in px to adjust space
                opacity: isReady ? 1 : 0, // Ensure the component is visible from the start
                transition: "opacity 0.3s ease", // Smooth transition when component becomes visible
            }}
        >
            {timerComponents}
        </div>
    )
}

addPropertyControls(Countdown, {
    font: {
        type: ControlType.Font, // Correctly use the font control
        defaultValue: "Inter", // Default font to "Inter"
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "black",
    },
    resetTime: {
        type: ControlType.String,
        defaultValue: "00:00:00",
    },
    numberFontSize: {
        type: ControlType.Number,
        unit: "px",
        defaultValue: 30,
    },
    textFontSize: {
        type: ControlType.Number,
        unit: "px",
        defaultValue: 12,
    },
    componentGap: {
        type: ControlType.Number,
        unit: "px",
        defaultValue: 20, // Default gap between components, adjustable
    },
})
